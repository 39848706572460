<template>
  <div>
    <div class="title">服务市场</div>

    <el-divider></el-divider>

    <!-- filter -->
    <div class="filter_box">
      <div class="filter_search">
        <el-input
          class="filter_search_input"
          placeholder="请输入关键词"
          v-model="search.keyword"
          clearable
        >
        </el-input>
        <div class="custom_button no_select">搜索</div>
      </div>
    </div>

    <el-tabs
      style="margin: 20px 0;"
      v-model="activeName"
      @tab-click="handleClick"
    >
      <el-tab-pane
        v-for="item in panes"
        :key="item.id"
        :label="item.name"
        :name="item.name"
      >
        <div class="goods_list">
          <div class="goods_item" v-for="item in tableData" :key="item.id" @click="applyService(item.id)">
            <el-image
              :src="item.picture || require('@/assets/temp/1.png')"
              fit="cover"
              style="width: 364px; height: 272px;"
            ></el-image>
            <div class="goods_info">
              <div><span>￥</span>{{ item.price }}</div>
              <div>已成交{{ item.count_order_completed }}笔</div>
            </div>
            <div class="goods_title">{{ item.name }}</div>
            <div class="goods_user">
              {{ item.user.nickname
              }}{{ item.user_is_auth == 1 ? "（已认证）" : "" }}
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>

    <el-pagination
      layout="prev, pager, next"
      :total="page.total"
      :page-size="page.size"
      :current-page.sync="page.page"
      @current-change="loadData"
    >
    </el-pagination>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      activeName: "推荐服务",
      panes: [
        {
          id: 0,
          name: "推荐服务",
        },
      ],
      page: {
        size: 15,
        page: 1,
        total: 0,
      },
      search: {
        keyword: "",
      },
      tableData: [],

      tableHeaderStyle: {
        backgroundColor: "#F5F8FA",
        textAlign: "center",
      },
      tableCellStyle: {
        textAlign: "center",
      },
    };
  },
  created() {
    this.getPlatformServicesCate()
      .then((res) => {
        console.log(res);
        this.panes = this.panes.concat(res.data.data.list);
        // 加载第一个分类
        if (this.panes[0]) {
          this.activeName = this.panes[0].name;
          this.loadData((loadDataObj) => {
            loadDataObj.cate_id = this.panes[0].id;
          });
        }
      })
      .catch((e) => {
        console.error(e);
      });
  },
  methods: {
    ...mapActions("flexibleEmployment", [
      "getPlatformServicesCate",
      "getServiceSupermarketList",
      "getServiceApply",
    ]),

    applyService(id) {
      this.$confirm("是否申请此服务?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
      })
        .then(() => {
          this.getServiceApply({ id })
            .then((res) => {
              console.log(res);
              this.$message.success(res.data.msg);
            })
            .catch((e) => {
              console.log(e);
              this.$message.error(e.data?.msg || "申请服务失败");
            });
        })
    },

    loadData(func) {
      // 单例初始化
      if (!this.loadDataObj)
        this.loadDataObj = new LoadDataClass(0, this.getServiceSupermarketList);

      // 通知，用于修改对象属性
      func(this.loadDataObj);

      let loading = this.$loading();
      this.loadDataObj
        .load(this.page.page)
        .then((res) => {
          console.log(res);
          this.tableData = res.data.data.list;
          this.page.total = res.data.data.total;
          loading.close();
        })
        .catch((e) => {
          loading.close();
          this.$message.error(e);
        });
    },

    /**
     * 搜索
     */
    toSearch() {
      this.page.page = 1;
      this.loadData((loadDataObj) => {
        loadDataObj.keyword = this.search.keyword;
      });
    },

    handleClick(tab, event) {
      console.log(tab, event);
      this.page.page = 1;
      this.loadData((loadDataObj) => {
        loadDataObj.cate_id = this.panes[tab.index].id;
      });
    },
  },
};

class LoadDataClass {
  constructor(cate_id, request, keyword = null) {
    this.cate_id = cate_id;
    this.func = request;
    this.keyword = keyword;
  }

  load(page) {
    let params = {
      cate_id: this.cate_id,
    };
    if (this.keyword) params.keywords = this.keyword;
    params.page = page;
    return this.func(params);
  }
}
</script>

<style lang="scss" scoped>
.title {
  font-size: 14px;
  font-weight: bold;
  color: #1c2438;
}

.filter_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0;

  .filter_search {
    display: flex;
    align-items: center;

    .filter_search_input {
      width: 680px;
      height: 36px;
      margin-right: 10px;

      ::v-deep .el-input__inner {
        height: 36px;
      }
    }
  }

  .filter_btns {
    display: flex;
    align-items: center;

    > div:not(:first-child) {
      margin-left: 20px;
    }
  }
}

.el-tabs {
  width: 1200px;

  ::v-deep .el-tabs__header {
    margin: 0;
  }

  ::v-deep .el-tabs__item {
    font-size: 16px;
    color: #495060;
    height: 45px;

    &.is-active {
      color: #4db9d5;
    }
  }

  ::v-deep .el-tabs__active-bar {
    background-color: #4db9d5;
  }
}

.goods_list {
  display: flex;
  flex-wrap: wrap;

  .goods_item {
    width: 364px;
    margin: 0 15px;
    margin-top: 40px;
    cursor: pointer;

    .goods_info {
      padding: 0 5px;
      margin: 10px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #f94d09;

      > div:nth-child(1) {
        font-size: 18px;

        > span {
          font-size: 14px;
        }
      }

      > div:nth-child(2) {
        font-size: 12px;
        color: #495060;
      }
    }

    .goods_title {
      padding: 0 5px;
      font-size: 16px;
      color: #1c2438;
      margin: 10px 0;
    }

    .goods_user {
      padding: 0 5px;
      font-size: 12px;
      color: #495060;
    }
  }
}
</style>
